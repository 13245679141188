<template>
  <v-container
    id="login"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snack"
      color="primary"
      bottom
    >
      {{ textoSnack }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snack = false"
        >
          cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <v-navigation-drawer
      touchless
      absolute
      width="100%"
      permanent
    >
      <v-row justify="center">
        <v-card
          color="rgba(255, 255, 255, .2)"
          light
          max-width="80%"
          width="400"
        >
          <v-card-text class="text-center">
            <div class="d-flex flex-column justify-space-between align-center py-3">
              <v-img
                src="assets/banner.png"
                max-width="250"
              />
            </div>

            <v-form
              ref="form"
              lazy-validation
            >
              <v-text-field
                v-model="usuario"
                :disabled="carga"
                :rules="regla"
                label="Usuario"
                color="white"
                dark
                outlined
                dense
                prepend-inner-icon="mdi-face-recognition"
                onkeydown="if (event.keyCode == 13)
                        document.getElementById('btnLog').click()"
              />
              <v-text-field
                v-model="clave"
                :disabled="carga"
                :rules="regla"
                type="password"
                label="Clave"
                color="white"
                dark
                outlined
                dense
                prepend-inner-icon="mdi-lock-outline"
                onkeydown="if (event.keyCode == 13)
                        document.getElementById('btnLog').click()"
              />
            </v-form>

            <v-btn
              id="btnLog"
              block
              color="primary"
              @click="loginData()"
            >
              <v-icon left>
                mdi-scale-balance
              </v-icon>
              Ingresar
            </v-btn>
          </v-card-text>
          <div
            class="text-body-2 white--text text-center"
            @click="irManual()"
          >
            Manual de usuario <v-icon
              small
              color="white"
            >
              mdi-book-open-variant
            </v-icon>
          </div>
          <div
            class="text-body-2 white--text text-center"
          >
            {{ new Date().getFullYear() }} &copy; CSJLA
          </div>
          <br>
        </v-card>
      </v-row>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'Login',
    components: {
    },

    data: () => ({
      usuario: '',
      clave: '',
      carga: false,
      sedeCorteData: '',
      snack: false,
      textoSnack: '',
      regla: [v => !!v || 'El campo es requerido'],
      urlFondo: '',
      urlLogo: '',
    }),
    created () {
      this.$store.commit('SET_VALOR', true)
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioDataCSJLA'))
      if (this.dataUsuario) {
        this.$router.replace(
          { path: 'data' },
          () => {
            this.$router.go(0)
          },
        )
      }
    },
    methods: {
      irManual () {
        window.open('assets/manual.pdf', '_blank')
      },
      loginData () {
        if (this.$refs.form.validate()) {
          this.carga = true
          const data = {
            usuario: this.usuario,
            clave: this.clave,
          }
          axios
            .post(`${window.__env.dataCfg.urlApiData}login/`, data)
            .then((r) => {
              if (r.data.resp === true) {
                localStorage.setItem('usuarioDataCSJLA', JSON.stringify(r.data.user))
                this.carga = false
                this.$router.push(
                  { path: r.data.user.ruta },
                  () => {
                    window.location.reload()
                  },
                )
              } else {
                this.textoSnack = 'DATOS INCORRECTOS'
                this.snack = true
                this.carga = false
              }
            // this.$router.go()
            })
            .catch((err) => {
              alert('Servicio no disponible' + err)
            })
        }
      },
    },
  }
</script>
<style lang="sass">
.v-card
  position: absolute
  top: 40%
  left: 50%
  transform: translate(-50%, -50%)
  border-radius: 16px
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1)
  backdrop-filter: blur(10px)
  -webkit-backdrop-filter: blur(10px)
  border: 1px solid rgba(255, 255, 255, .4)

.v-navigation-drawer
  background-image: url('/assets/fondop.webp')
  background-size: cover
</style>
